<script>
import LinkBox from '../components/LinkBox.vue';

export default {
  components: {
    LinkBox,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      multipleSlides: false,
      canScrollLeft: false,
      canScrollRight: false,
    };
  },
  computed: {
    title() {
      return this.block.fields.Title;
    },
    heading() {
      return this.block.fields.HeadingLevel?.value || 'h4';
    },
    links() {
      // eslint-disable-next-line camelcase
      return this.block.fields.CompositeLink_Links;
    },
  },
  methods: {
    addIntersectionObserver() {
      const slides = this.container.children;
      const options = {
        root: this.container,
        threshold: 1,
      };

      const callback = () => {
        this.canScrollLeft = this.container.scrollLeft > 0;
        this.canScrollRight = this.container.scrollLeft < this.container.scrollWidth
                                                        - this.container.clientWidth;
      };

      this.observer = new IntersectionObserver(callback, options);

      slides.forEach((element) => {
        this.observer.observe(element);
      });
    },
    slideDistance() {
      return this.container.scrollWidth / this.container.children.length;
    },
    goPrev() {
      if (this.canScrollLeft) {
        this.container.scrollLeft -= this.slideDistance();
      }
    },
    goNext() {
      if (this.canScrollRight) {
        this.container.scrollLeft += this.slideDistance();
      }
    },
  },
  mounted() {
    this.container = this.$refs.container;
    this.addIntersectionObserver();
    this.multipleSlides = this.container.children.length > 1;
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
};
</script>

<template>
  <div class="featured-posts__container">
    <component :is="heading" v-if="title"
      class="featured-posts__title"
    >
      {{title}}
    </component>
    <div class="featured-posts__link-container" ref="container">
      <link-box
        v-for="(link, idx) in links"
        :key="idx"
        :link="link"
      />
    </div>
    <div
      v-if="multipleSlides"
      class="featured-posts__navigation"
      ref="navigation"
    >
      <div
        @click="goPrev"
        class="featured-posts__navigation-prev"
        :style="{opacity: canScrollLeft ? 1 : 0,
        pointerEvents: canScrollLeft ? 'auto' : 'none'}"
      >
      </div>
      <div
        @click="goNext"
        class="featured-posts__navigation-next"
        :style="{opacity: canScrollRight ? 1 : 0,
        pointerEvents: canScrollRight ? 'auto' : 'none'}"
      >
      </div>
    </div>
  </div>
</template>

<style>
  .featured-posts__container {
    max-width: calc(var(--header-max-width) + 20px);
    margin: auto;
    padding: 20px 0;
    overflow: hidden;
  }

  .featured-posts__title {
    text-align: left;
    color: var(--color-black);
    margin-bottom: 14px;
    padding: 0 10px;
  }

  .featured-posts__link-container{
    display: flex;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 1rem;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 0 10px;
    gap: 16px;
  }

  .featured-posts__link-container::-webkit-scrollbar {
    display: none; /*hide scroll for Chrome, Safari, and Opera */
  }

  .featured-posts__navigation {
    display: flex;
    margin: 25px auto 10px;
    padding: 0 25px;
  }

  .featured-posts__navigation-prev {
    transform: rotate(-180deg);
    margin-right: auto;
  }

  .featured-posts__navigation-prev,
  .featured-posts__navigation-next {
    background-image: url('../static/icons/arrow_right_black_icon.svg');
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: opacity 0.1s linear;
  }

  @media (--tabletAndDesktop) {
    .featured-posts__title {
      margin-bottom: 24px;
    }

    .featured-posts__link-container{
      gap: 30px;
    }
  }

  /* LinkBox component styling */

  .featured-posts__link-container .link-box__container {
    width: 74%;
    scroll-snap-align: center;
  }

  .featured-posts__link-container .link-box__image-spacer {
    padding-top: 100%;
  }

  .featured-posts__link-container .link-box__subtitle {
    font-size: 14px;
  }

  @media (--phone) {
    .featured-posts__link-container .link-box__image-container{
      margin-top: 0;
    }
  }

  @media (--tabletAndDesktop) {
    .featured-posts__link-container .link-box__image-spacer {
      padding-top: 52.543%;
    }

    .featured-posts__link-container .link-box__container {
      width: 590px;
    }
  }
</style>
